<template>
 <div class="about_wrap">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_setting.lang_arr[3].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[3].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <div class="container">
        <!-- {{get_type_projecta}} -->
        <div class="flex_type">
              <a class="type_proj" data-filter="all" >
                {{get_setting.lang_arr[29].translate}}
            </a>
            <template v-for="type in get_type_projecta">
                <a class="type_proj" :data-filter="`.${type.slug}`"  :key="type.id">
                {{type.name}}
            </a>
            </template >
        </div>
              <div class="row row_filter">    
                <div :class="`mix ${project.category_slug} col-xl-4 col-md-6 col-12`"  v-for="project in get_all_projects"  :key="project.id">
                <router-link :to="`/our-projects/${project.id}`" class="box">
                    <img :src="project.image" :alt="project.name" />
                    <div class="box-content">
                        <h3>{{project.name}}</h3>
                        <p>{{project.short_desc}}</p>
                    </div>
                </router-link>
            </div>
        </div>
       </div>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixitup from 'mixitup';
import $ from 'jquery';
export default {
  name: "AllProjectApp",
  data(){
      return{}
      },
    computed:{
      ...mapGetters({
        get_all_projects:'get_all_projects',
        get_type_projecta:'get_type_projecta',
        get_setting:'get_setting'
      })
    },
    methods:{
      ...mapActions({
        all_project_api:'all_project_api',
        type_project_api:'type_project_api'
      })
    },
    mounted(){
     this.uk.offcanvas($('#offcanvas-sidebar')).hide();
      this.type_project_api().then(()=>{
         this.all_project_api().then(()=>{
        var containerEl = document.querySelector('.row_filter');
        var mixer = mixitup(containerEl);
        console.log(mixer)
      });
      });
    }
};
</script>
<style lang="scss"  scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_type{
    margin-bottom: 30px;
}
.type_proj{
    background-color: var(--active_color);
    color: white;
    padding: 10px 32px;
    margin-bottom: 14px;
    margin-right: 14px;
    border-radius: 30px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    text-transform: capitalize;
    &:hover{
        transform: scale(1.06);
    }
}
.type_proj.mixitup-control-active{
    background-color: var(--active_color2);
    color: #000;
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 40px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}
// card
      .box {
            color: #fff;
            font-size: 16px;
            overflow: hidden;
            position: relative;
            text-align: right;
            -webkit-transition: all 0.45s ease;
            transition: all 0.45s ease;
            padding: 0px;
            margin-bottom: 50px;
            height: 290px;
            width: 100%;
        }
        .box img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .box:before,
        .box:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: var(--active_color);
            opacity: 0.5;
            -webkit-transition: all 0.45s ease;
            transition: all 0.45s ease;
        }

        .box:before {
            -webkit-transform: skew(30deg) translateX(-80%);
            transform: skew(30deg) translateX(-80%);
        }

        .box:after {
            -webkit-transform: skew(-30deg) translateX(-70%);
            transform: skew(-30deg) translateX(-70%);
        }
        .box:hover:before,
        .box.hover:before {
            -webkit-transform: skew(30deg) translateX(-30%);
            transform: skew(30deg) translateX(-30%);
            -webkit-transition-delay: 0.05s;
            transition-delay: 0.05s;
        }

        .box:hover:after,
        .box.hover:after {
            -webkit-transform: skew(-30deg) translateX(-20%);
            transform: skew(-30deg) translateX(-20%);
        }
        .box .box-content {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            z-index: 1;
            bottom: 0;
            padding: 20px 20px 20px 10%;
            width: 100%;
            text-align: left;
            transition: 0.3s ease-in-out;
        }

        .box .box-content:before,
        .box .box-content:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: var(--active_color);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
            opacity: 0.5;
            z-index: -1;
            -webkit-transition: all 0.45s ease;
            transition: all 0.45s ease;
        }

        .box .box-content:before {
            -webkit-transform: skew(30deg) translateX(-100%);
            transform: skew(30deg) translateX(-100%);
        }

        .box .box-content:after {
            -webkit-transform: skew(-30deg) translateX(-90%);
            transform: skew(-30deg) translateX(-90%);
        }
        .box:hover .box-content:before,
        .box.hover .box-content:before {
            -webkit-transform: skew(30deg) translateX(-50%);
            transform: skew(30deg) translateX(-50%);
            -webkit-transition-delay: 0.15s;
            transition-delay: 0.15s;
        }

        .box:hover .box-content:after,
        .box.hover .box-content:after {
            -webkit-transform: skew(-30deg) translateX(-40%);
            transform: skew(-30deg) translateX(-40%);
            -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
        }
        .box h3,
        .box p {
            margin: 0;
            opacity: 0;
            letter-spacing: 0px;
             transition: 0.3s ease-in-out;
        }

        .box h3 {
           font-size: 25px;
            font-weight: 700;
            margin-bottom: 10px;
            text-transform: capitalize;
            color: white;
            padding-top: 50px;
        }

        .box p {
            font-size: 14px;
        }

        .box:hover h3,
        .box.hover h3,
        .box:hover p,
        .box.hover p {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 0.9;
        }

        @media screen and (max-width: 990px){
            .box{
                margin-bottom: 30px;
            }
        }
@media screen and (max-width:768px){
  .box h3{
    font-size:18px;
  }
}

</style>