<template>
<footer class="footerApp">
   <div class="container">
       <div class="row">
           <div class="col-lg-4 box_single_foot">
               <div class="title_footer">
                  <h1>{{get_setting.lang_arr[26].translate}}</h1>
             </div>
             <div class="box_footer">
                 <img class="logo_footer" :src="get_setting.logo" alt="logo">
                 <p class="text_about">
                    {{get_setting.footer_text}}
                 </p>
             </div>
           </div>
           <div class="col-lg-4 box_single_foot">
               <div class="title_footer">
                  <h1>{{get_setting.lang_arr[27].translate}}</h1>
             </div>
             <div class="box_footer links_footer">
                 <div class="row">
                     <div class="col-6">
                         <router-link to="/"><ion-icon name="shapes-outline"></ion-icon> {{get_setting.lang_arr[0].translate}}</router-link>
                     </div>
                     <div class="col-6">
                          <router-link to="/about-us"><ion-icon name="shapes-outline"></ion-icon> {{get_setting.lang_arr[1].translate}}</router-link>
                     </div>
                     <div class="col-6">
                          <router-link to="/our-projects"><ion-icon name="shapes-outline"></ion-icon> {{get_setting.lang_arr[3].translate}}</router-link>
                     </div>
                     <div class="col-6">
                          <router-link to="/our-team"><ion-icon name="shapes-outline"></ion-icon> {{get_setting.lang_arr[4].translate}}</router-link>
                     </div>
                     <div class="col-6">
                          <router-link to="/publications"><ion-icon name="shapes-outline"></ion-icon>{{get_setting.lang_arr[5].translate}}</router-link>
                     </div>
                      <div class="col-6">
                          <router-link to="/contact-us"><ion-icon name="shapes-outline"></ion-icon>{{get_setting.lang_arr[6].translate}}</router-link>
                     </div>
                      <!-- <div class="col-6">
                          <router-link to="/"><ion-icon name="shapes-outline"></ion-icon>{{get_setting.lang_arr[25].translate}}</router-link>
                     </div> -->
                 </div>
             </div>
           </div>
           <div class="col-lg-4 box_single_foot">
               <div class="title_footer">
                  <h1>{{get_setting.lang_arr[28].translate}}</h1>
             </div>
             <div class="box_footer contact_info">
                 <a href="#" class="info_a" v-if="get_setting.address">
                     <span class="title_cont"><ion-icon name="location-outline"></ion-icon> {{get_setting.lang_arr[13].translate}}</span>
                     <span class="data_cont">{{get_setting.address}}</span>
                 </a>
                  <a :href="`tel:${get_setting.phone}`" class="info_a" v-if="get_setting.phone">
                     <span class="title_cont"><ion-icon name="call-outline"></ion-icon> {{get_setting.lang_arr[14].translate}}</span>
                     <span class="data_cont">{{get_setting.phone}}</span>
                 </a>
                  <a :href="`tel:${get_setting.phone2}`" class="info_a" v-if="get_setting.phone2">
                     <span class="title_cont"><ion-icon name="call-outline"></ion-icon> {{get_setting.lang_arr[14].translate}}</span>
                     <span class="data_cont">{{get_setting.phone2}}</span>
                 </a>

                 
                 <a :href="`mailto:${get_setting.email}`" class="info_a" v-if="get_setting.email">
                     <span class="title_cont"><ion-icon name="mail-unread-outline"></ion-icon> {{get_setting.lang_arr[15].translate}}</span>
                     <span class="data_cont">{{get_setting.email}}</span>
                 </a>
                 <a href="#" class="info_a"  v-if="get_setting.fax">
                     <span class="title_cont"><ion-icon name="print-outline"></ion-icon> {{get_setting.lang_arr[16].translate}}</span>
                     <span class="data_cont">{{get_setting.fax}}</span>
                 </a>
                 <div class="icon_social_footer">
                <a :href="get_setting.twitter" target="_black" v-if="get_setting.twitter" class="social_top"><ion-icon name="logo-twitter"></ion-icon></a>
                <a :href="get_setting.facebook" target="_black" v-if="get_setting.facebook" class="social_top"><ion-icon name="logo-facebook"></ion-icon></a>
                <a :href="get_setting.instagram" target="_black" v-if="get_setting.instagram" class="social_top"><ion-icon name="logo-instagram"></ion-icon></a>
                    <a :href="get_setting.youtube" target="_black" v-if="get_setting.youtube" class="social_top"><ion-icon name="logo-youtube"></ion-icon></a>
                    <a :href="get_setting.linkedin" target="_black" v-if="get_setting.linkedin" class="social_top"><ion-icon name="logo-linkedin"></ion-icon></a>
                     <a :href="get_setting.whatsapp" target="_black" v-if="get_setting.whatsapp" class="whatsapp"><ion-icon name="logo-whatsapp"></ion-icon></a>
            </div>
             </div>
           </div>
       </div>
   </div>
   <div class="banner_copy">
                   <p>{{get_setting.copyright}}</p>
               </div>
</footer>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: "footerApp",
  components: {},

  data(){
      return{ }},
      computed:{
      ...mapGetters({
        get_setting:'get_setting'
      })
    },
};
</script>
<style lang="scss" scoped>
.footerApp{
    margin-top: 50px;
    padding: 40px 0px 0px 0px;
    background-color: #1a1a1a;
    color: #979797;
    .title_footer{
   text-align: left;
   h1{
     color: white;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    position: relative;
     &::after{
    content: "";
    width: 50%;
    height: 2px;
    background-color: var(--active_color);
    position: absolute;
    left: 0;
    right: auto;
    margin-left: auto;
    margin-right: auto;
    bottom: -10px;
   }
 }

 }
 .box_footer{
     padding-top: 40px;
     .logo_footer{
             width: auto;
             height: 80px;
             object-fit: contain;
     }
     p.text_about{
         font-size: 13px;
         padding-top: 14px;
     }
 }
 .links_footer{
     &::after{
         content: '';
         background-image: url('~@/assets/map1.png');
             position: absolute;
            width: 200px;
            height: 100px;
            right: 40px;
            bottom: 70px;
            z-index: 2;
     }
     a{
         color:#979797;
         margin-bottom: 20px;
         display: inline-block;
         transition: 0.3s ease-in-out;
         ion-icon{
             margin-right: 4px;
         }
         &:hover{
             color: white;
         }
     }
 }
 .contact_info{
     a.info_a{
         color: #979797;
         display: block;
         margin-bottom: 20px;
         ion-icon{
             font-size: 20px;
             vertical-align: middle;
             margin-top: -8px;
             padding-right: 4px;
         }
     }
.title_cont{
    font-weight: 600;
    font-size: 15px;
    padding-right: 10px;
    display: inline-block;
}
.icon_social_footer{
    display: flex;
    padding-top: 20px;
    a{
    margin-right: 20px;
    background-color: gray;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    transition: 0.3s ease-in-out;
    &:hover{
        background-color: var(--active_color);
    }
    }
}
 }
 .banner_copy{
     background-color: black;
     padding: 14px 2px;
     text-align: center;
     margin-top: 60px;
     color: white;
     text-transform: capitalize;
     font-size: 13px;
 }
}

@media(max-width:992px){
.box_single_foot{
    margin-bottom: 30px;
}
.box_single_foot:last-child{
    margin-bottom: 0px;
}
}
</style>