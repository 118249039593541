<template>
 <div class="about_wrap">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_setting.lang_arr[4].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[4].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <div class="container">
            <div class="title_strat">
            </div>
           <!-- team -->
           <section>
    <section class="team-page-section">
            <div class="row clearfix">

                <!-- Team Block -->
                <div class="team-block col-xl-4 col-md-6 col-sm-12" v-for="team in get_our_team" :key="team.id">
                    <div class="inner-box wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                        <ul class="social-icons">
                            <li v-if="team.facebook"><a target="_blanck" :href="team.facebook"><i class="fab fa-facebook-f"></i></a></li>
                            <li v-if="team.twitter"><a target="_blanck" :href="team.twitter"><i class="fab fa-twitter"></i></a></li>
                            <li v-if="team.instagram"><a target="_blanck" :href="team.instagram"><i class="fab fa-instagram"></i></a></li>
                            <li v-if="team.linkedin"><a target="_blanck" :href="team.linkedin"><i class="fab fa-linkedin-in"></i></a></li>
                             <li v-if="team.email" ><a target="_blanck" :href="`mailto:${team.email}`"><i class="fas fa-envelope"></i></a></li>
                        </ul>
                       
                        <div class="image">
                             <template v-if="team.image">
                            <span> <img :src="team.image" :alt="team.name"></span>
                        </template>
                         <template v-else>
                             <span><img :src="get_setting.logo" alt="logo"></span>
                         </template>
                        </div>
                        <div class="lower-content">
                            <h3><span >{{team.name}} </span></h3>
                            <div class="designation">{{team.job}}</div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
</section>
       </div>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
  name: "teamApp",
  data(){
      return{}
      },
    computed:{
      ...mapGetters({
        get_our_team:'get_our_team',
         get_setting:'get_setting'
      })
    },
    methods:{
      ...mapActions({
        our_team_api:'our_team_api'
      })
    },
    mounted(){
     this.uk.offcanvas($('#offcanvas-sidebar')).hide();
      this.our_team_api();
    }
};
</script>
<style lang="scss"  scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 0px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}

// team

.team-page-section{
    position:relative;
    padding-top:70px;
}

.team-page-section .team-block{
    margin-bottom:60px;
}

.sec-title{
    position:relative;
    z-index:1;
    margin-bottom:65px;
}

.sec-title .title{
    position:relative;
    font-size:18px;
    color:#bd0000;
    font-weight:700;
    margin-bottom:12px;
    text-transform:capitalize;
}

.sec-title .separator{
    position:relative;
    width:40px;
    margin-top:16px !important;
}

.sec-title .separator:before{
    position:absolute;
    content:'';
    left:0px;
    top:0px;
    width:10px;
    height:2px;
    background-color:var(--active_color2);
}

.sec-title .separator:after{
    position:absolute;
    content:'';
    right:0px;
    top:0px;
    width:10px;
    height:2px;
    background-color:var(--active_color2);
}

.sec-title .separator span{
    position:absolute;
    left:50%;
    top:-2px;
    width:10px;
    height:5px;
    margin-left:-5px;
    display:inline-block;
    background-color:#bd0000;
}

.sec-title h2{
    position:relative;
    color:#222222;
    font-weight:700;
    font-size:36px;
    line-height:1.2em;
    margin-top:35px;
}

.sec-title .text{
    position:relative;
    font-size:14px;
    line-height:1.9em;
    color:#555555;
    margin-top:10px;
}

.sec-title.centered .separator{
    margin:0 auto !important;
    float:none !important;
}

.sec-title.centered{
    text-align: center !important;
}

.sec-title.light .title{
    color:#ffffff;
}

.sec-title.light h2{
    color:#ffffff;
}

.sec-title.light .separator span{
    background-color:var(--active_color);
}

.sec-title.light .separator:before,
.sec-title.light .separator:after{
    background-color:#ffffff;
}

.team-section{
    position:relative;
    padding:80px 0px 60px;
}

.team-section.style-two .pattern-layer{
    position:absolute;
    left:0px;
    top:0px;
    right:0px;
    bottom:0px;
    background-position:center center;
    background-repeat:no-repeat;
}

.team-section .title-box{
    position:relative;
    text-align:center;
}

.team-section .title-box h2{
    position: relative;
    color: #222222;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.3em;
    margin-bottom: 50px;
}

.team-section .title-box h2 span{
    color: var(--active_color2);
}

.team-block{
    position:relative;
}

.team-block .inner-box{
    position:relative;
    padding:40px 70px 28px 0px;
}

.team-block .inner-box:before{
    position:absolute;
    content:'';
    right:0px;
    top:0px;
    left:70px;
    bottom:0px;
    border-radius:10px;
    background-color:#f5f5f5;
    -webkit-transition:all 600ms ease;
    -moz-transition:all 600ms ease;
    -ms-transition:all 600ms ease;
    -o-transition:all 600ms ease;
    transition:all 600ms ease;
}

.team-block .inner-box::after{
    position:absolute;
    content:'';
    top:0px;
    left:70px;
    bottom:0px;
    width:0%;
    border-radius:10px;
    -webkit-transition:all 600ms ease;
    -moz-transition:all 600ms ease;
    -ms-transition:all 600ms ease;
    -o-transition:all 600ms ease;
    transition:all 600ms ease;
    background-image: -ms-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: -moz-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: -o-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, var(--active_color)), color-stop(100, var(--active_color2)));
    background-image: -webkit-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: linear-gradient(to bottom, var(--active_color) 0%, var(--active_color2) 100%);
}

.team-block .inner-box:hover::after{
    width:81%;
}

.team-block .inner-box .social-icons{
    position:absolute;
    right:25px;
    top:100px;
    z-index:1;
}

.team-block .inner-box .social-icons li{
    position:relative;
    margin-bottom:20px;
}

.team-block .inner-box .social-icons li a{
    position:relative;
    color:#e40000;
    font-size:18px;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.team-block .inner-box:hover .lower-content h3 a,
.team-block .inner-box:hover .social-icons li a,
.team-block .inner-box:hover .lower-content .designation{
    color:#ffffff;
}

.team-block .inner-box .image{
    position:relative;
    z-index:1;
    border-radius:10px;
    background-color:#ffffff;
    // box-shadow:inset 0px 0px 35px rgba(0,0,0,0.20);
}

.team-block .inner-box .image img{
    position:relative;
    width:100%;
    display:block;
    height: 350px;
    object-fit: contain;
}

.team-block .inner-box .lower-content{
    position:relative;
    text-align:left;
    z-index:1;
    padding:30px 15px 0px 110px;
}

.team-block .inner-box .lower-content h3{
    position:relative;
    color:#bd0000;
    font-size:18px;
    font-weight:700;
    line-height:1.3em;
}

.team-block .inner-box .lower-content h3 a{
    position:relative;
    color:#bd0000;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.team-block .inner-box .lower-content .designation{
    position:relative;
    margin-top:5px;
    color:#777777;
    font-size:14px;
}

.team-block.style-two .inner-box .lower-content h3 a,
.team-block.style-two .inner-box .social-icons li a{
    color:var(--active_color2);
}

.team-block.style-two .inner-box:hover .lower-content h3 a,
.team-block.style-two .inner-box:hover .social-icons li a{
    color:#ffffff;
}

.team-block.style-two .inner-box::after{
    background-image: -ms-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: -moz-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: -o-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, var(--active_color)), color-stop(100, var(--active_color2)));
    background-image: -webkit-linear-gradient(top, var(--active_color) 0%, var(--active_color2) 100%);
    background-image: linear-gradient(to bottom, var(--active_color) 0%, var(--active_color2) 100%);
}
</style>