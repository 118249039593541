<template>
 <div class="about_wrap last_news">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_setting.lang_arr[31].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[31].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab last_news_wrap">
       <div class="container">
              <div class="row">    
                <div class="slide_box_news col-xl-4 col-md-6 col-12" v-for="blog in get_all_blogs" :key="blog.id">
            <div class="uk-panel">
                <div class="img_box">
                  <img :src="blog.image" width="400" height="600" :alt="blog.title">
                </div>
                <div class="news_body">
                  <h1>{{blog.title}}</h1>
                  <p v-html="blog.desc"></p>
                 <router-link :to="`/blogs/info/${blog.id}`">{{get_setting.lang_arr[30].translate}}<ion-icon name="arrow-redo-outline"></ion-icon></router-link>
                </div>
            </div>
        </div>
        </div>
       </div>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
  name: "AllProjectApp",
  data(){
      return{}
      },
    computed:{
      ...mapGetters({
        get_all_blogs:'get_all_blogs',
        get_setting:'get_setting'
      })
    },
    methods:{
      ...mapActions({
       all_blogs_api:'all_blogs_api'
      })
    },
    mounted(){
     this.uk.offcanvas($('#offcanvas-sidebar')).hide();
     this.all_blogs_api()
    }
};
</script>
<style lang="scss"  scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_type{
    margin-bottom: 30px;
}
.type_proj{
    background-color: var(--active_color);
    color: white;
    padding: 10px 32px;
    margin-bottom: 14px;
    margin-right: 14px;
    border-radius: 30px;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    transition: 0.2s ease-in-out;
    text-transform: capitalize;
    &:hover{
        transform: scale(1.06);
    }
}
.type_proj.mixitup-control-active{
    background-color: var(--active_color2);
    color: #000;
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 40px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}
// card
  .last_news{
  padding-top: 50px;
  .last_news_wrap{
    padding-top: 40px;
    .slide_box_news{
        margin-bottom: 35px;
      &:hover{
        .uk-panel{
          img{
            transform: scale(1.1);
          }
        }
      }
      .uk-panel{
         border: 1px solid gainsboro;
      }
      .img_box{
        overflow: hidden;
      }
      .news_body{
        padding: 14px 8px;
        color: black;
        min-height: 120px;
        h1{
          color: black;
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 8px;
        }
        p{
          font-size: 14px;
          opacity: 0.5;
          padding-top: 2px;
        }
        a{
          color: var(--active_color2);
          font-size: 14px;
          font-weight: 500;
          margin-top: 8px;
          display: inline-block;
          transition: 0.2s ease-in-out;
          ion-icon{
            vertical-align: middle;
          }
          &:hover{
            color: var(--active_color);
          }
        }
      }
      img{
        height: 240px;
        width: 100%;
        max-width: 100%;
        object-fit: cover;
        transition: 0.2s linear;
      }
    }
  }
}

</style>