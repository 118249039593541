<template>
  <teamApp></teamApp>
</template>

<script>
import teamApp from '@/components/pages/teamApp.vue';
export default {
  components: { teamApp },
  name: "TeamPage",
};
</script>