import axios from 'axios';
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    slider_home:{},
    service_slider_home:{},
    service_home:{},
    projects_home:{},
    blogs_home:{},
    about_us:{},
    out_team:{},
    rate:{},
    clients:{},
    setting:{},
    all_projects:{},
    type_projecta:{},
    details_projects:{},
    all_publications:{},
    service_category_nav:{},
    service_main:{},
    service_details:{},
    home_setting:{},
    all_blogs:{},
    blog_details:{},
    timeline_details:{}
  },
  getters: {
    get_slider_home(state){
    return state.slider_home;
    },
    get_service_slider_home(state){
      return state.service_slider_home;
    },
    get_service_home(state){
      return state.service_home;
    },
    get_projects_home(state){
      return state.projects_home;
    },
    get_blogs_home(state){
      return state.blogs_home;
    },
    get_about_us(state){
      return state.about_us;
    },
    get_our_team(state){
      return state.out_team;
    },
    get_rate(state){
      return state.rate;
    },
    get_clients(state){
      return state.clients;
    },
    get_setting(state){
      return state.setting;
    },
    get_all_projects(state){
      return state.all_projects;
    },
    get_type_projecta(state){
      return state.type_projecta;
    },
    get_details_projects(state){
      return state.details_projects;
    },
    get_all_publications(state){
      return state.all_publications;
    },
    get_service_category_nav(state){
      return state.service_category_nav;
    },
    get_service_main(state){
      return state.service_main;
    },
    get_service_details(state){
      return state.service_details;
    },
    get_home_setting(state){
      return state.home_setting;
    },
    get_all_blogs(state){
      return state.all_blogs;
    },
    get_blog_details(state){
      return state.blog_details;
    },
    get_timeline_details(state){
      return state.timeline_details;
    },
    
    
    
    
  },
  mutations: {
    set_slider_home(state,slider_home){
        state.slider_home =  slider_home;
    },
    set_service_slider_home(state,service_slider_home){
      state.service_slider_home = service_slider_home;
    },
    set_service_home(state,service_home){
      state.service_home = service_home;
    },
    set_projects_home(state,projects_home){
      state.projects_home = projects_home;
    },
    set_blogs_home(state,blogs_home){
      state.blogs_home = blogs_home;
    },
    set_about_us(state,about_us){
      state.about_us = about_us;
    },
    set_our_team(state,out_team){
      state.out_team = out_team;
    },
    set_rate(state,rate){
      state.rate = rate;
    },
    set_clients(state,clients){
      state.clients = clients;
    },
    set_setting(state,setting){
      state.setting = setting;
    },
    set_all_projects(state,all_projects){
      state.all_projects = all_projects;
    },
    set_type_projecta(state,type_projecta){
      state.type_projecta = type_projecta;
    },
    set_details_projects(state,details_projects){
      state.details_projects = details_projects;
    },
    set_all_publications(state,all_publications){
      state.all_publications = all_publications;
    },
    set_service_category_nav(state,service_category_nav){
      state.service_category_nav = service_category_nav;
    },
    set_service_main(state,service_main){
      state.service_main = service_main;
    },
    set_service_details(state,service_details){
      state.service_details = service_details;
    },
    set_home_setting(state,home_setting){
      state.home_setting = home_setting;
    },
    set_all_blogs(state,all_blogs){
      state.all_blogs = all_blogs;
    },
    set_blog_details(state,blog_details){
      state.blog_details = blog_details;
    },
    set_timeline_details(state,timeline_details){
      state.timeline_details = timeline_details;
    },
    
    
  },
  actions: {
    async slider_home_api({commit}){
        await axios({ method: 'get', url: '/home-data?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_slider_home' ,res.data.data.sliders);
        commit('set_service_slider_home',res.data.data.slider_services);
        commit('set_service_home',res.data.data.services);
        commit('set_projects_home',res.data.data.projects);
        commit('set_blogs_home',res.data.data.blogs);
        commit('set_rate',res.data.data.rates);
        commit('set_clients',res.data.data.clients);
        });
      },
      async about_us_api({commit}){
        await axios({ method: 'get', url: '/about-us?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_about_us',res.data.about);
        });
      },
      async our_team_api({commit}){
        await axios({ method: 'get', url: '/work-team?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_our_team',res.data.data);
        });
      },
      async setting_api({commit}){
        await axios({ method: 'get', url: '/settings?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
          console.log(res.data , 'setting');
          commit('set_setting',res.data.data);
        if(localStorage.getItem('Fav_lang')==null){
          localStorage.setItem('Fav_lang', 'en');
         }else if(localStorage.getItem('Fav_lang')=='ar'){
           localStorage.setItem('Fav_lang', 'ar');
         }else{
          localStorage.setItem('Fav_lang', 'en');
         } 
        });
      },
      async contact_form_api(_,data_form){
        let response_contact_form={};
        await axios({ method: 'post', url: '/store-contact', data:{
          'name':data_form.name,
          'email':data_form.email,
          'subject':data_form.subject,
          'message':data_form.message,
        }, validateStatus: () => true }).then(res=>{
        response_contact_form = res.data;
        });
        return response_contact_form;
      },
      async all_project_api({commit}){
        await axios({ method: 'get', url: '/projects?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_all_projects',res.data.data);
        });
      },
      async type_project_api({commit}){
        await axios({ method: 'get', url: '/projects-types?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_type_projecta',res.data.data);
        });
      },
      async details_projects_api({commit},project_id){
        await axios({ method: 'post', url: '/project-details?lang='+localStorage.getItem('Fav_lang'),data:{
          'id':project_id
        }, validateStatus: () => true }).then(res=>{
        console.log(res.data.data);
        commit('set_details_projects',res.data.data);
        });
      },
      async all_publication_api({commit}){
        await axios({ method: 'get', url: '/publications?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_all_publications',res.data.data);
        });
      },
      async service_category_nav_api({commit}){
        await axios({ method: 'get', url: '/services-categories?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        console.log(res.data);
          commit('set_service_category_nav',res.data.data);
        });
      },
      async service_main_api({commit},id_service){
        await axios({ method: 'post', url: '/category-services-details?lang='+localStorage.getItem('Fav_lang'),data:{
          'id':id_service
        }, validateStatus: () => true }).then(res=>{
        console.log(res.data);
          commit('set_service_main',res.data.data);
        });
      },
      async service_details_api({commit},id_service){
        await axios({ method: 'post', url: '/service-details?lang='+localStorage.getItem('Fav_lang'),data:{
          'id':id_service
        }, validateStatus: () => true }).then(res=>{
        console.log(res.data);
          commit('set_service_details',res.data.data);
        });
      },
      async home_setting_api({commit}){
        await axios({ method: 'get', url: '/home-settings?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_home_setting',res.data.data);
        });
      },
      async all_blogs_api({commit}){
        await axios({ method: 'get', url: '/blogs?lang='+localStorage.getItem('Fav_lang'), validateStatus: () => true }).then(res=>{
        commit('set_all_blogs',res.data.data);
        });
      },
      async blog_details_api({commit},id_blog){
        await axios({ method: 'post', url: '/blog-details?lang='+localStorage.getItem('Fav_lang'),data:{
          'blog_id':id_blog
        }, validateStatus: () => true }).then(res=>{
          commit('set_blog_details',res.data.data);
        });
      },
       async timeline_details_api({commit},id){
        await axios({ method: 'post', url: '/publication-details?lang='+localStorage.getItem('Fav_lang'),data:{
          'id':id
        }, validateStatus: () => true }).then(res=>{
          commit('set_timeline_details',res.data.data);
        });
      },
      
      
    // get String 
    // async get_string_api({commit}){
    //   await axios({ method: 'get', url: '/string', validateStatus: () => true }).then(res=>{
       
    //   });
    // },
  }
  , modules: {}
})

