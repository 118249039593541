<template>
  <projectApp></projectApp>
</template>

<script>
import projectApp from '@/components/pages/projectApp.vue';
export default {
  components: { projectApp },
  name: "TeamPage",
};
</script>