<template>
  <div id="app">
      <headerApp></headerApp>
       <router-view></router-view>
      <footerApp></footerApp>
      <div  v-if="loader" id="loader"></div>
      <div  v-if="loader1" id="loader1"></div>
  </div>
</template>

<script>
import headerApp from "./components/global/headerApp.vue";
import footerApp from "./components/global/footerApp.vue";
import { mapGetters } from 'vuex';
export default {
  name: "App",
  components: {
    headerApp,
    footerApp
  },

  data: () => ({
    loader:true,
    loader1:false
  }),
 watch: {
        $route (to, from) {
          console.log(from,to);
          this.loader=true;
         setTimeout(()=>{
        this.loader = false;
      },1000)
    }
        }
      ,
         computed:{
      ...mapGetters({
         get_setting:'get_setting'
      })
    },
    methods:{
    hide_loader(){
      setTimeout(()=>{
        this.loader = false;
      },2000)
    }
  },
  
  mounted(){
    this.hide_loader();
  }
};
</script>
