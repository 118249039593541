<template>
<div class="all_wrap service_wrapx">
    <div style="min-height:100vh" v-if="!get_timeline_details">
        laoder app
</div>
 <div class="about_wrap descr" v-if="get_timeline_details">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_timeline_details.title}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><a href="#">{{get_setting.lang_arr[0].translate}}</a></li>
    <li><a href="#">{{get_timeline_details.title}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <div class="container">
      <div class="row">
      <div class="col-12">
        <div class="text-center img_timeline">
            <img :src="get_timeline_details.image" alt="image">
        </div>
      </div>
          <div class="col-12">
            <div class="text_details_s">
                <h1 class="title_t">{{get_timeline_details.title}}</h1>
                <p v-html="get_timeline_details.desc"></p>
            </div>
          </div>
         
      </div>
       </div>
     </div>
   
 </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
    name:"ProjectInfo",
    data() {
        return {
            gallery: false
        }
    },
    computed:{
      ...mapGetters({
        get_timeline_details:'get_timeline_details',
        get_setting:'get_setting'
      })
    },
    methods: {
        ...mapActions({
        timeline_details_api:'timeline_details_api',
      }),
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        }
    },
    mounted(){
         this.uk.offcanvas($('#offcanvas-sidebar')).hide();
      this.timeline_details_api(this.$route.params.id).then((res)=>{
        console.log('loader end', res);
      });

    // console.log(this.$s)
    },
     destroyed(){
          this.$store.commit('set_service_details', null);
    }
}
</script>
<style lang="scss" scoped>
.img_timeline{
    img{
        width: 100%;
    height: 500px;
    object-fit: cover;
    }
    margin-bottom: 50px;
}
.descr{
    min-height: 100vh;
}
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 50px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}
// slider
.slider_col{
    margin-bottom: 35px;
}
.is-active .al img {
    border: 1px solid #fff;
    filter: grayscale(0%);
}
.al img {
    border: 1px solid transparent;
    filter: grayscale(100%);
}
.title_proj{
    position: relative;
   h1{
        font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: auto;
        margin-left: auto;
        margin-right: auto;
        bottom: -10px;
        height: 2px;
        width: 14%;
        background-color: var(--active_color);
    }
   }
}
.text_proj{
    p{
        font-size: 14px;
        font-weight: 400;
        opacity: 0.6;
        margin-bottom: 14px;
    }
}
.single_check{
        display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed rgb(148, 146, 146);
    margin-bottom: 14px;
    padding-bottom: 14px;
}
.left_check{
    display: flex;
    align-items: center;
    margin-right: 12px;
    .title_check{
        font-size: 14px;
        font-weight: 500;
    }
    ion-icon{
        margin-right: 10px;
        font-size: 24px;
        vertical-align: middle;
        color: var(--active_color);
        font-weight: 600;
    }
}
.data_cehck{
    font-size: 14px;
    opacity: 0.6;
}
// client
.client_name{
    margin-top: 25px;
    .title_proj{
        margin-bottom: 35px;
    }
    .single_check{
        width: 100% !important;
        border-bottom: 0px !important;
        .left_check{
            margin-right: 0px !important;
        }
    }
}
.text_details_s{
    min-height: 500px;
    h1{
        font-size: 25px;
        font-weight: 500;
        color: var(--active_color);
        margin-bottom: 14px;
    }
    p{
        font-size: 15px;
        opacity: 0.8;
    }
}
</style>