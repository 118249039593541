<template>
 <div class="about_wrap">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_setting.lang_arr[17].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[17].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <div class="container">
            <div class="title_strat">
                <h1>{{get_about_us.title}}</h1>
            </div>
             <blockquote cite="#">
                <p v-html="get_about_us.desc" class="uk-margin-small-bottom">
                   
                </p>
            </blockquote>
            <!-- video iframe or video -->
           <div class="text-center wrap_video">
            <template v-if="get_about_us.youtube">
                <iframe class="video_about"  :src="`https://www.youtube.com/embed/${get_about_us.youtube}?autoplay=1&mute=1&loop=1`" title="YouTube video player" frameborder="0" allow="accelerometer;autoplay;clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </template>
                <template v-else>
                    <video class="video_about" :src="get_about_us.vedio" autoplay loop muted playsinline></video>
            </template>
           </div>
           <!-- vission and misstion -->
           <hr>
           <template v-for="(text_about,index) in get_about_us.text">
           <div :key="text_about.id">
            <div :class="`our_vm${index+1}`">
               <h1><strong>{{text_about.name}}</strong></h1>
               <p v-html="text_about.desc"></p>
           </div>
            <hr :class="`hr_${index+1}`">
           </div>
           </template>
           <!-- team -->
          <div class="manager_sec ">
            <div class="flex_man">
                <div class="flex_man_img">
                    <div class="wrap_img1">
                        <template v-if="get_about_us.maneger_image">
                             <img :src="get_about_us.maneger_image" :alt="get_about_us.maneger_title">
                        </template>
                         <template v-else>
                             <img :src="get_setting.logo" alt="logo">
                         </template>
                        
                    </div>
               
            </div>
            <div class="flex_man_text">
               <div class="text_man">
                 <h1>{{get_about_us.maneger_title}}</h1>
                <p v-html="get_about_us.maneger_desc"></p>
               </div>
            </div>
            </div>
          </div>
       </div>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
  name: "AboutApp",
  data(){
      return{}
      },
    computed:{
      ...mapGetters({
        get_about_us:'get_about_us',
         get_our_team:'get_our_team',
          get_setting:'get_setting'
      })
    },
    methods:{
      ...mapActions({
        about_us_api:'about_us_api',
         our_team_api:'our_team_api'
      })
    },
    mounted(){
      this.uk.offcanvas($('#offcanvas-sidebar')).hide();
      this.about_us_api();
      this.our_team_api();
    }
};
</script>
<style lang="scss" scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 50px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}

// team
.manager_sec{
        border: 2px solid #ebebeb;
         transition: 0.3s ease-in-out;
    padding: 20px;
    border-radius: 20px;
        background-color: gainsboro;
        &:hover{
              background-color: rgb(247, 247, 247);
            img{
                 background: #fff!important;
            }
        }
    .flex_man{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .flex_man_img{
        flex: 0 0 300px;
    }
    .flex_man{
        flex: 0 0 calc(100% - 300px);
    }
img{
    width: 300px;
    height: 300px;
    object-fit: contain;
    margin-bottom: 25px;
    background: #ebebeb!important;
    transition: 0.3s ease-in-out;
    border-radius: 100%;
    padding: 25px;
    object-fit: cover;
}
.wrap_img1{
    text-align: center;
}
.row{
    align-items: center;
}
.text_man{
    margin-top: -40px;
    padding-left: 25px;
}
h1{
    font-size: 30px;
    color: var(--active_color);
    margin-bottom: 10px;
    font-weight: 700;
}
h2{
    font-size: 20px;
    color: var(--active_color);
    margin-bottom: 10px;
}
p{
    font-size: 15px;
    opacity: 0.8;
}
}
@media(max-width:992px){
    .manager_sec{
        text-align: center;
        h1{
    font-size: 22px;
        }
          .flex_man{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }
    .flex_man_img{
        flex: 0 0 100%;
    }
    .flex_man{
        flex: 0 0 100%;
    }
    .text_man{
        margin-top: 10px;
    }
}
}
</style>