<template>
 <div class="about_wrap">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_setting.lang_arr[7].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[7].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <div class="container">
     <div class="title_strat">
                <h1>{{get_service_main.name}}</h1>
            </div>
             <blockquote cite="#" class="blockqute">
                <p class="uk-margin-small-bottom" v-html="get_service_main.desc"></p>
            </blockquote>
            <hr>
            <div class="row">
                <div class="col-xl-4 col-md-6 col-12" v-for="service in get_service_main.services" :key="service.id">
                     <router-link :to="`/service/info/${service.id}`">
                        <div class="service_box" :style="`background-image: url('${service.image}');`">
                        <p class="name_service" v-html="service.title"></p>
                    </div>
                    </router-link>
                </div>
            </div>
       </div>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
  name: "MainServiceApp",
   computed:{
      ...mapGetters({
       get_service_main:'get_service_main',
         get_setting:'get_setting'
      })
    },
    watch: {
        $route (to, from) {
          if(to.name =='AllService' && from.name =='AllService'){
            this.service_main_api(this.$route.params.id);
          }
        
        }
      },
    methods:{
      ...mapActions({
        service_main_api:'service_main_api'
      })
    },
    mounted(){
       this.uk.offcanvas($('#offcanvas-sidebar')).hide();
        this.service_main_api(this.$route.params.id);
    }
};
</script>
<style lang="scss"  scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 55px;
    .title_strat{
        h1{
        font-size: 23px;
        font-weight: 500;
        color: var(--active_color);
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
       ul{
        list-style: unset !important;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}
div.service_box {
  width: 100%;
  height: 250px;
  background: #fd9;
  margin: 10px 0px 30px 0px;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.2);
  transition: .3s;
  background-size: cover;
  background-position: center center;
  position: relative;
  p{
    position: absolute;
    z-index: 10;
    left: 0px;
    bottom: 0px;
    color: white;
    font-weight: 500;
    font-size: 16px;
    background-color: var(--active_color);
    padding: 4px 10px;
  }
  &::before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}
div.service_box:hover {
  box-shadow: 0px 20px 40px rgba(0,0,0,0.4);
  transform: scale(1.05,1.05);
 
}
</style>