<template>
  <AboutApp></AboutApp>
</template>

<script>
import AboutApp from '@/components/pages/aboutApp.vue';
export default {
  components: { AboutApp },
  name: "AboutPage",
};
</script>