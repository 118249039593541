<template>
 <div class="about_wrap">
     <div class="flex_subb">
          <div class="container">
        <div class="title_banner_sub">
        <div class="title_page">{{get_setting.lang_arr[5].translate}}</div>
        <ul class="uk-breadcrumb">
    <li class="firstbread"><router-link to="/">{{get_setting.lang_arr[0].translate}}</router-link></li>
    <li><a href="#">{{get_setting.lang_arr[5].translate}}</a></li>
</ul>
    </div>
    </div>
     </div>
     <div class="page_start_ab">
       <div class="container">
        <!-- {{get_all_publications}} -->
        <!-- new time line -->
        <ul class="timeLineUs">
        <template v-for="publication in get_all_publications">
 <li style="--accent-color:#0870b8" :key="publication.id">
     <router-link :to="`/publications/info/${publication.id}`">
        <div class="date">{{publication.year}}</div>
        <div class="image_publication">
          <img :src="publication.image" :alt="publication.title">
        </div>
        <div class="title">{{publication.title}}</div>
        <div class="descr">{{publication.short_desc}}</div>
   </router-link>
    </li>
        </template>
</ul>
       </div>
     </div>
   
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import $ from 'jquery';
export default {
  name: "allPublicationApp",
   computed:{
      ...mapGetters({
        get_all_publications:'get_all_publications',
         get_setting:'get_setting'
      })
    },
    methods:{
      ...mapActions({
        all_publication_api:'all_publication_api'
      })
    },
    mounted(){
       this.uk.offcanvas($('#offcanvas-sidebar')).hide();
      this.all_publication_api();
    }
};
</script>
<style lang="scss"  scoped>
.about_wrap{
    padding-top: 150px;
    min-height: calc(100vh - 150px);
}
.flex_subb{
  background-color: #f4f4f4;
    padding: 30px 10px;
}
.title_banner_sub{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title_page{
        font-size: 30px;
        font-weight: 400;
    }
    .firstbread{
        a{
            color: var(--active_color);
        }
    }
}
// 
.page_start_ab{
    padding-top: 80px;
    .title_strat{
        h1{
        font-size: 22px;
        font-weight: 400;
        }
    }
    blockquote{
       p{
            font-size: 15px;
            padding-top: 8px;
            opacity: 0.6;
       }
    }
    .wrap_video{
        margin-top: 50px;
        .video_about{
        text-align: center;
        width: 100%;
        height: 600px;
        margin: 0 auto;
    }
    }
   .our_vm1 , .our_vm2, .our_vm3{
        margin-top: 30px;
        font-weight: 400;
        h1{
            font-size: 30px;
            padding-bottom: 10px;
            strong{
                color: var(--active_color);
            }
        }
    } 
}

//time lien

.timer-section {
  min-height: 100vh;
}
.timer-section .container {
  padding-left: 15px;
  padding-right: 15px;
}

.timer-section .timer-container {
  list-style: none;
}
.timer-section .timer {
  list-style: none;
  position: relative;
  max-width: 750px;
  margin: auto;
}
.timer-section .timer::before {
  /* content: ''; */
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #F2F2F2;
  left: 50%;
  transform: translateX(-50%);
}

.timer-section .timer .step {
  width: 50%;
  padding: 20px 15px;
  position: relative;
  background-color: #F2F2F2;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .04);
}
.timer-section .timer .step:not(:first-child),
.timer-section .timer .step:not(:last-child) {
  margin: 90px 0 60px 0;
}
.timer-section .timer .step:nth-child(odd) {
  transform: translateX(100%);
  border-left: 2px solid lightblue;
  border-radius: 0 3px 3px 0;
}
.timer-section .timer .step:nth-child(even) {
  border-right: 2px solid lightsalmon;
  border-radius: 3px 0 0 3px;
}

.timer-section .timer .step > span {
  position: absolute;
  height: 26px;
  width: 25px;
  color: #333;
  background-color: #F2F2F2;
  text-align: center;
  line-height: 22px;
  border-radius: 3px;
  font-size: .8rem;
}
.timer-section .timer .step:nth-child(odd) > span {
  top: -40px;
  left: -13px;
  border: 2px solid lightblue;
}
.timer-section .timer .step:nth-child(even) > span {
  top: -40px;
  right: -13px;
  border: 2px solid lightsalmon;
}

.timer-section .timer .step h3 {
  margin-bottom: 15px;
}
.timer-section .timer .step p {
  font-weight: 300;
  font-size: .85rem;
  color: #555;
}

@media screen and (max-width: 500px) {
  .timer-section .timer .step {
    width: 100%;
    text-align: center;
    transform: translateX(0%);
    border-radius: 3px !important;
  }
  .timer-section .timer .step:nth-child(odd) {
    transform: translateX(0%);
    border: none;
    border-top: 2px solid lightblue;
  }
  .timer-section .timer .step:nth-child(even) {
    border: none;
    border-top: 2px solid lightsalmon;
  }
  .timer-section .timer .step span {
    left: 50% !important;
    transform: translateX(-50%);
  }
}


// new Time Line
.timeLineUs {
  --col-gap: 2rem;
  --row-gap: 2rem;
  --line-w: 0.25rem;
  display: grid;
  grid-template-columns: var(--line-w) 1fr;
  grid-auto-columns: max-content;
  column-gap: var(--col-gap);
  list-style: none;
  width: min(60rem, 90%);
  margin-inline: auto;
}

/* line */
.timeLineUs::before {
  content: "";
  grid-column: 1;
  grid-row: 1 / span 20;
  background: rgb(225, 225, 225);
  border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.timeLineUs li:not(:last-child) {
  margin-bottom: var(--row-gap);
}

/* card */
.timeLineUs li {
  grid-column: 2;
  --inlineP: 1.5rem;
  margin-inline: var(--inlineP);
  grid-row: span 2;
  display: grid;
  grid-template-rows: min-content min-content min-content;
}

/* date */
.timeLineUs li .date {
  --dateH: 3rem;
  height: var(--dateH);
  margin-inline: calc(var(--inlineP) * -1);

  text-align: center;
  background-color: var(--accent-color);

  color: white;
  font-size: 1.25rem;
  font-weight: 700;

  display: grid;
  place-content: center;
  position: relative;

  border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.timeLineUs li .date::before {
  content: "";
  width: var(--inlineP);
  aspect-ratio: 1;
  background: var(--accent-color);
  background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
  position: absolute;
  top: 100%;

  clip-path: polygon(0 0, 100% 0, 0 100%);
  right: 0;
}

/* circle */
.timeLineUs li .date::after {
  content: "";
  position: absolute;
  width: 2rem;
  aspect-ratio: 1;
  background: var(--bgColor);
  border: 0.3rem solid var(--accent-color);
  border-radius: 50%;
  top: 50%;
  background-color: white;
  transform: translate(50%, -50%);
  right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.timeLineUs li .title,
.timeLineUs li .descr {
  background: var(--bgColor);
  position: relative;
  padding-inline: 1.5rem;
}
.timeLineUs li .title {
  overflow: hidden;
  padding-block-start: 1.5rem;
  padding-block-end: 1rem;
  font-weight: 500;
}
.timeLineUs li .descr {
  padding-block-end: 1.5rem;
  font-weight: 300;
  color: #333;
}

/* shadows */
.timeLineUs li .title::before,
.timeLineUs li .descr::before {
  content: "";
  position: absolute;
  width: 90%;
  height: 0.5rem;
  background: rgba(0, 0, 0, 0.5);
  left: 50%;
  border-radius: 50%;
  filter: blur(4px);
  transform: translate(-50%, 50%);
}
.timeLineUs li .title::before {
  bottom: calc(100% + 0.125rem);
}

.timeLineUs li .descr::before {
  z-index: -1;
  bottom: 0.25rem;
}

@media (min-width: 55rem) {
  .timeLineUs {
    grid-template-columns: 1fr var(--line-w) 1fr;
  }
  .timeLineUs::before {
    grid-column: 2;
  }
  .timeLineUs li:nth-child(odd) {
    grid-column: 1;
  }
  .timeLineUs li:nth-child(even) {
    grid-column: 3;
  }

  /* start second card */
  .timeLineUs li:nth-child(2) {
    grid-row: 2/4;
  }

  .timeLineUs li:nth-child(odd) .date::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    left: 0;
  }

  .timeLineUs li:nth-child(odd) .date::after {
    transform: translate(-50%, -50%);
    left: calc(100% + var(--col-gap) + var(--line-w) / 2);
  }
  .timeLineUs li:nth-child(odd) .date {
    border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
  }
}


</style>