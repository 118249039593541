<template>
  <homeApp></homeApp>
</template>

<script>
import homeApp from '@/components/home/homeApp.vue';
export default {
  components: { homeApp },
  name: "Home",
    mounted(){
         document.body.classList.add('HomePage');
    },
    destroyed(){
      document.body.classList.remove('HomePage');
    }
};
</script>