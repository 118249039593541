<template>
  <contactApp></contactApp>
</template>

<script>
import contactApp from '@/components/pages/contactApp.vue';
export default {
  components: { contactApp },
  name: "ContactPage",
};
</script>