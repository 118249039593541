import Vue from "vue";
import App from "./App.vue";
import store from './store/store.js'
import "./registerServiceWorker";
document.cookie = "SameSite";
import axios from 'axios';

// imports Views
import Home from '@/views/Home.vue';
import About from '@/views/About.vue';
import Team from '@/views/Team.vue';
import Project from '@/views/Project.vue';
import Timeline from '@/views/Timeline.vue';
import TimelineDetails from '@/components/pages/timelinedetails.vue';
import Contact from '@/views/Contact.vue';
import ProjectInfo from '@/components/pages/projectinfoApp.vue';
import AllService from '@/components/pages/mainserviceApp.vue';
import ServiceDetails from '@/components/pages/servicedetailsApp.vue';
import AllNews from '@/components/pages/newsApp.vue';
import NewsDetails from '@/components/pages/newdetails.vue';
// vue add vuetify
// npm install bootstrap jquery popper.js --save
// npm i uikit
// import vuetify from "./plugins/vuetify";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import uk from 'uikit'
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js'
Vue.mixin({
  data: function () {
    return {
      get uk () {
        return uk
      },
    }
  },
});
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy);
import './scss/main.scss';
// end lib import

// import Vue Router
// npm i vue-router@3.6.5
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    document.querySelector('body').scrollIntoView({ behavior: 'instant' });
},
routes: [
  { path: '/', component: Home },
  { path: '/home', component: Home, name: "Home" },
  { path: '/about-us', component: About, name: "About" },
  { path: '/our-team', component: Team, name: "Team" }, 
  { path: '/our-projects', component: Project, name: "Project" }, 
  { path: '/our-projects/:id', component: ProjectInfo, name: "ProjectInfo" }, 
  { path: '/publications', component: Timeline, name: "Timeline" }, 
  { path: '/publications/info/:id', component: TimelineDetails, name: "TimelineDetails" }, 
  { path: '/contact-us', component: Contact, name: "Contact" }, 
  { path: '/service/:id', component: AllService, name: "AllService" }, 
  { path: '/service/info/:id', component: ServiceDetails, name: "ServiceDetails" }, 
  { path: '/blogs', component: AllNews, name: "AllNews" }, 
  { path: '/blogs/info/:id', component: NewsDetails, name: "NewsDetails" }, 
  
]
});

// google map
// npm install vue2-google-maps
import * as VueGoogleMaps from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: '#',
    libraries: 'places',
  }
});


// npm i vuex@3.6.2 ==> store
// npm i vue-axios
// axios.defaults.baseURL = "https://platin-jo.com/admin/api";
 axios.defaults.baseURL = "https://heavysteel-sa.com/admin/api";
// axios.interceptors.request.use(
//   config => {
//     config.headers['Cache-Control'] = "no-cache",
//     config.headers['content-type'] = "application/vnd.api+json",
//     config.headers['Access-Control-Max-Age'] = 290304000
//       return config
//   },
//   error => {
//       Promise.reject(error)
//   }
// )

Vue.config.ignoredElements = [/^ion-/]
Vue.config.productionTip = false;
store.dispatch('setting_api').then(()=>{
const myfav = store.getters['get_setting'];
  const favicon = document.querySelectorAll("link[rel~='icon']")
  favicon.forEach((elem)=>{
    console.log(myfav.fav_icon)
    elem.href = myfav.fav_icon;
  })
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

