<template>
  <timelineApp></timelineApp>
</template>

<script>
import timelineApp from '@/components/pages/timelineApp.vue';
export default {
  components: { timelineApp },
  name: "TimeLinePage",
};
</script>