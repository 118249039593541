<template>
<div class="header_wrap_app" :class="[ (nav_scroll)==true ? 'nav_scroll':'']">
<div class="top_banner_nav">
   <div class="container-lg flex_up_banner">
        <div class="icon_social">
        <a :href="get_setting.twitter" target="_black" v-if="get_setting.twitter" class="social_top"><ion-icon name="logo-twitter"></ion-icon></a>
                <a :href="get_setting.facebook" target="_black" v-if="get_setting.facebook" class="social_top"><ion-icon name="logo-facebook"></ion-icon></a>
                <a :href="get_setting.instagram" target="_black" v-if="get_setting.instagram" class="social_top"><ion-icon name="logo-instagram"></ion-icon></a>
                    <a :href="get_setting.youtube" target="_black" v-if="get_setting.youtube" class="social_top"><ion-icon name="logo-youtube"></ion-icon></a>
                    <a :href="get_setting.linkedin" target="_black" v-if="get_setting.linkedin" class="social_top"><ion-icon name="logo-linkedin"></ion-icon></a>
                     <a :href="get_setting.whatsapp" target="_black" v-if="get_setting.whatsapp" class="whatsapp"><ion-icon name="logo-whatsapp"></ion-icon></a>
    </div>
    <div class="mobile_email">
         <b-field class="fld">
            <!--  -->
            <b-select class="lang_sel" placeholder="Select a name" v-model="active_val" @change.native="changeLang($event)">
                 <option class="enopt" value="en">🇺🇸&nbsp;&nbsp;English</option>
                <option  class="aropt" value="ar">🇯🇴&nbsp;&nbsp;العربية</option>
            </b-select>
        </b-field>
        <a class="mob_aa ffrr" :href="`tel:${get_setting.phone}`" v-if="get_setting.phone">
            <div class="icon_mob"><ion-icon name="call-outline"></ion-icon></div>
            <div class="data_mob">{{get_setting.phone}}</div>
        </a>
        <a class="mob_aa" :href="`mailto:${get_setting.email}`" v-if="get_setting.email">
            <div class="icon_mob"><ion-icon name="mail-unread-outline"></ion-icon></div>
            <div class="data_mob">{{get_setting.email}}</div>
        </a>
    </div>
   </div>
</div>
<div class="navbar_scroll">
    <div class="container nav_scroll_contanier">
        <div class="logo_nav">
            <router-link to="/"><img :src="get_setting.logo" alt="logo"></router-link>
        </div>
        <div class="link_nav">
            <router-link v-if="get_setting.act_home" class="par_a" to="/">{{get_setting.lang_arr[0].translate}}</router-link>
             <router-link v-if="get_setting.act_about" class="par_a" to="/about-us">{{get_setting.lang_arr[1].translate}}</router-link>
              <!-- <a href="#">What we do</a> -->
            <b-dropdown  class="par_a" :triggers="['hover']" aria-role="list">
            <template #trigger>
                <b-button
                    :label="get_setting.lang_arr[2].translate"
                    type="is-info"
                    icon-right="menu-down" />
            </template>
            <template v-for="service in get_service_category_nav">
                 <router-link :key="service.id" :to="`/service/${service.id}`"><b-dropdown-item aria-role="listitem">{{service.name}}</b-dropdown-item></router-link>
            </template>
        </b-dropdown>
               <router-link v-if="get_setting.act_projects" class="par_a" to="/our-projects">{{get_setting.lang_arr[3].translate}}</router-link>
                <router-link v-if="get_setting.act_teams" class="par_a" to="/our-team">{{get_setting.lang_arr[4].translate}}</router-link>
                  <router-link v-if="get_setting.act_blog" class="par_a" to="/blogs">{{get_setting.lang_arr[31].translate}}</router-link>
                 <router-link v-if="get_setting.act_publications" class="par_a" to="/publications">{{get_setting.lang_arr[5].translate}}</router-link>
                  <router-link v-if="get_setting.act_contact" class="par_a" to="/contact-us">{{get_setting.lang_arr[6].translate}}</router-link>
        </div>
        <span class="menu_mobile_btn" uk-toggle="#offcanvas-sidebar"><ion-icon name="menu-outline"></ion-icon></span>
    </div>
</div>
     <div id="offcanvas-sidebar" uk-offcanvas>
            <div class="uk-offcanvas-bar">
                <button class="uk-offcanvas-close" type="button" uk-close></button>
                 <div class="logo_sidebar">
                     <router-link to="/"><img :src="get_setting.logo" alt="logo"></router-link>
                 </div>
                 <ul class="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
					<li v-if="get_setting.act_home"><router-link to="/"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[0].translate}}</router-link></li>
					
                    <li v-if="get_setting.act_about"><router-link to="/about-us"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[1].translate}}</router-link></li>
                   
                   <li class="uk-parent"><a href="#"><span data-uk-icon="icon: thumbnails" class="uk-margin-small-right"></span> {{get_setting.lang_arr[2].translate}}</a>
						<ul class="uk-nav-sub">
							<li v-for="service in get_service_category_nav" :key="service.id">
                                <router-link :to="`/service/${service.id}`">{{service.name}}</router-link>
                            </li>
							
						</ul>
					</li>
					
                    <li v-if="get_setting.act_projects"><router-link to="/our-projects"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[3].translate}}</router-link></li>
                    <li v-if="get_setting.act_teams"><router-link to="/our-team"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[4].translate}}</router-link></li>
                    <li v-if="get_setting.act_blog" ><router-link to="/blogs"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[31].translate}}</router-link></li>
                    <li v-if="get_setting.act_publications" ><router-link to="/publications"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[5].translate}}</router-link></li>
                    <li v-if="get_setting.act_contact"><router-link to="/contact-us"><span data-uk-icon="icon: comments" class="uk-margin-small-right"></span>{{get_setting.lang_arr[6].translate}}</router-link></li>
				</ul>
                <b-field class="fld">
            <!--  -->
            <b-select class="lang_sel" placeholder="Select a name" v-model="active_val" @change.native="changeLang($event)">
                 <option class="enopt" value="en">🇺🇸&nbsp;&nbsp;English</option>
                <option  class="aropt" value="ar">🇯🇴&nbsp;&nbsp;العربية</option>
            </b-select>
        </b-field>
           <!-- <ul class="uk-nav uk-nav-primary uk-nav-left uk-margin-auto-vertical">
            <router-link  to="/">
                Home Page
               </router-link>
               <router-link  to="/">
                About Us
               </router-link>
        </ul> -->
            </div>
        </div>
</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: "headerApp",
  components: {},

  data(){
      return{
           nav_scroll:false,
            active_val:''
      }
  },
   computed:{
      ...mapGetters({
        get_our_team:'get_our_team',
        get_service_category_nav:'get_service_category_nav',
         get_setting:'get_setting'
      })
    },
  methods:{
    ...mapActions({
        service_category_nav_api:'service_category_nav_api',
        setting_api:'setting_api'
      }),
       get_page(id){   
        this.service_details_api(id).then((res)=>{
        console.log('loader end', res);
      });
      },
      scroll_down_page(){
            document.addEventListener('scroll',()=> {
               if(window.scrollY>50){
                   this.nav_scroll = true;
               }
               else{
                   this.nav_scroll = false;
               }
            });
        },
        changeLang(language){
            if(language.target.value=='en'){
              window.location.reload();
            this.active_lang = 'en';
            document.body.classList.remove('rtl');
            document.documentElement.classList.remove('rtl');
            localStorage.setItem('Fav_lang', 'en');
             this.uk.offcanvas('#offcanvas-flip', {
                flip : true,
            });
             this.setting_api();
            }
            else{
              window.location.reload();
            this.active_lang = 'ar';
            document.body.classList.add('rtl');
            document.documentElement.classList.add('rtl');
            localStorage.setItem('Fav_lang', 'ar');
             this.uk.offcanvas('#offcanvas-flip', {
                flip : false,
            });
             this.setting_api();
            }
        },
  },
  mounted(){
     this.active_val  = localStorage.getItem('Fav_lang');
        if(localStorage.getItem('Fav_lang')=='ar'){
            document.body.classList.add('rtl');
            document.documentElement.classList.add('rtl');

        }
        else{
            document.body.classList.remove('rtl');
            document.documentElement.classList.remove('rtl');
        }
      this.scroll_down_page();
      this.service_category_nav_api();
  }

};
</script>
<style lang="scss" scoped>
.uk-nav-parent-icon{
    padding-top: 20px;
    a{
        margin-bottom: 14px;
        color: white;
    }
}
.HomePage{
      .top_banner_nav{
        border-bottom: 1px solid rgb(236, 235, 235);
         position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 4;
        transition: 0.3s linear;
           &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: black !important;
            opacity: 0.3 !important;
        }
    }
     .navbar_scroll{
        position: fixed;
        left: 0;
        top: 52px;
        z-index: 100;
        width: 100%;
        transition: 0.3s linear;
           &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: black !important;
            opacity: 0.3 !important;
        }
     }
}
.flex_up_banner{
    position: relative;
}
.header_wrap_app{
    .top_banner_nav{
        border-bottom: 1px solid rgb(236, 235, 235);
         position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 4;
        transition: 0.3s linear;
           &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: var(--active_color);
            opacity: 1;
        }
    }
    .flex_up_banner{
        display: flex;
        justify-content: space-between;
        padding: 14px 0px;
        align-items: center;
        z-index: 2;
        .icon_social{
            a{
                margin-right: 20px;
            }
        }
        ion-icon{
            font-size: 18px;
            color: white;
        }
    // mobile email top
    .mobile_email{
        display: flex;
        align-items: center;
        .icon_mob{
            display: flex;
            align-items: center;
        }
        a{
            display: flex;
            align-items: center;
            color: white;
            font-size: 14px;
            ion-icon{
                margin-right: 16px;
                color: white;
            }
        }
    }
    }
    .menu_mobile_btn{
        display: none;
        cursor: pointer;
    }
    // nav_scroll_header
    .navbar_scroll{
        position: fixed;
        left: 0;
        top: 76px;
        z-index: 100;
        width: 100%;
        transition: 0.3s linear;
           &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0.7;
        }
        .nav_scroll_contanier{
            padding: 14px 0px;
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            width: 100%;
        }
        .logo_nav{
            flex: 0 0 90px;
            img{
                width: 80px;
                transition: 0.3s linear;
            object-fit: contain;
            }
        }
    .link_nav{
        flex: 0 0 calc(100% - 90px);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .par_a{
        height: 34px;
        display: flex;
        align-items: center;
        color: white;
        margin-right: 2.2rem;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        padding: 2px 12px;
        transition: 0.2s ease-in-out;
        border-radius: 8px;
        &:last-child{
            margin-right: 0px;
        }
        &:hover{
            background-color: var(--active_color);
            color: white;
            transform: scale(1.1);
            
        }
        }

    }
    }
}
  .nav_scroll{
    .top_banner_nav{
        position: fixed !important;
        opacity: 0 !important;
        top: -80px !important;
    }
      .navbar_scroll{
        top: 0px !important;   
        &::after{
            opacity: 0.7 !important;
        }
    }
     .navbar_scroll .logo_nav img{
            width: 60px;
        }
    }


@media(max-width:1220px){
    .header_wrap_app .navbar_scroll .link_nav a {
    margin-right: 1.8rem;
    padding: 2px 12px;
}
}
@media(max-width:992px){
    .link_nav{
        display: none !important;
    }
    .menu_mobile_btn{
        display: block !important;
        color: white;
        ion-icon{
            font-size: 30px;
        }
    }
}
@media(max-width:767px){
    .header_wrap_app .top_banner_nav{
        display: none !important;
    }
    .header_wrap_app .navbar_scroll{
        top: 0 !important;
    }
    .header_wrap_app .navbar_scroll .nav_scroll_contanier{
    padding: 14px;
}


}
</style>